import { useCallback, useState } from "react";

export function useLocalStorageBoolean(
  key: string,
  initialValue: boolean = false,
): [boolean, () => void] {
  const [storedValue, setStoredValue] = useState<boolean>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  const setValue = useCallback(
    (value: boolean | ((val: boolean) => boolean)) => {
      try {
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;
        setStoredValue(valueToStore);
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      } catch (error) {
        console.error(error);
      }
    },
    [key, storedValue],
  );

  const toggle = useCallback(() => {
    setValue((prevValue) => !prevValue);
  }, [setValue]);

  return [storedValue, toggle];
}
