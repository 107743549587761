import React, { useState } from "react";
import { useApi } from "@api/api";
import { PickupLines } from "@components/pickupLines";
import { HowTo } from "@components/howTo";
import { TitleBar } from "@components/titleBar";
import { ProfileEntry } from "@components/profileEntry";
import { UsernameEntry } from "@components/usernameEntry";
import { useLocalStorageCounter } from "@hooks/useLocalStorageCounter";
import { ShareButton } from "@components/shareButton";
import { useLocalStorageBoolean } from "@hooks/useLocalStorageBoolean";
import { CoffeeSupport } from "@components/coffeeSupport";
import { tagEvent } from "@components/util";
import Footer from "@components/Footer";
import { useButtonCooldown } from "@hooks/useButtonCooldown";

export const LandingView: React.FC = () => {
  const { usePickuplineQuery, refreshPickupline } = useApi();

  const [name, setName] = useState("");
  const [profile, setProfile] = useState("");
  const [req, setReq] = useState(null);
  const { data, isLoading, isFetching } = usePickuplineQuery(req);
  const [buttonClicks, incrementButtonClicks] = useLocalStorageCounter(
    "buttonClicks",
    0,
  );
  const [coffeeHidden, toggleCoffeeHidden] = useLocalStorageBoolean(
    "coffeeHidden",
    false,
  );
  const { timeLeft, startCooldown } = useButtonCooldown("generatePickupLines");

  const handleSubmit = (e) => {
    e.preventDefault();
    tagEvent("generatePickupLines");
    setReq({ name, profile });
    refreshPickupline({ name, profile });
    incrementButtonClicks();
    startCooldown();
  };
  const canSubmit =
    profile.length > 20 && !isLoading && !isFetching && timeLeft === 0;

  const buttonText =
    timeLeft > 0 ? `Wait ${timeLeft.toFixed(0)}s` : "Make me sound smooth";

  return (
    <div className="hero min-h-screen">
      <div className="hero-content flex-col">
        <TitleBar />
        <form onSubmit={handleSubmit} className="min-w-96">
          <ProfileEntry profile={profile} setProfile={setProfile} />
          <UsernameEntry name={name} setName={setName} />
          <button
            className="btn btn-primary btn-block"
            type="submit"
            disabled={!canSubmit}
          >
            {isLoading || isFetching ? (
              <span className="loading loading-infinity loading-lg"></span>
            ) : (
              buttonText
            )}
          </button>
        </form>
        <div className={"divider"} />
        {data && !isLoading && !isFetching && (
          <div className="max-w-md">
            <PickupLines lines={data.lines} />
            <div className="divider" />
            <div className="flex justify-center">
              {buttonClicks > 5 && <ShareButton />}
            </div>
          </div>
        )}

        {!isFetching && !data && <HowTo />}

        {data && buttonClicks > 20 && !coffeeHidden && (
          <CoffeeSupport onHide={toggleCoffeeHidden} />
        )}
        <Footer />
      </div>
    </div>
  );
};

export default LandingView;
