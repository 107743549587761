import React from "react";

interface UsernameEntryProps {
  name: string;
  setName: (name: string) => void;
}

export const UsernameEntry: React.FC<UsernameEntryProps> = ({
  name,
  setName,
}) => {
  return (
    <div className="mb-4">
      <label
        className="block text-gray-700 text-xs font-bold mb-2"
        htmlFor="name"
      >
        Whats their name? (optional)
      </label>
      <input
        className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow"
        id="name"
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Fran or @foodie_fran_98"
      />
    </div>
  );
};
