import React, { useEffect, useState } from "react";
import { useDebounce } from "@hooks/useDebounce";

interface ProfileEntryProps {
  profile: string;
  setProfile: (profile: string) => void;
}

export const ProfileEntry: React.FC<ProfileEntryProps> = ({
  profile,
  setProfile,
}) => {
  const characterCount = profile.length;
  const isOverLimit = characterCount > 500;
  const [showError, setShowError] = useState(false);
  const debouncedProfile = useDebounce(profile, 1500);

  useEffect(() => {
    setShowError(debouncedProfile.length > 0 && debouncedProfile.length < 20);
  }, [debouncedProfile]);

  useEffect(() => {
    if (profile.length >= 20 && showError) setShowError(false);
  }, [profile, showError]);

  return (
    <div className="relative">
      <label
        className="block text-gray-700 text-sm font-bold mb-2"
        htmlFor="profile"
      >
        Paste a profile
      </label>
      <textarea
        className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow min-h-32"
        id="profile"
        value={profile}
        onChange={(e) => setProfile(e.target.value)}
        placeholder="Love good food, sunny days, and laughter. In search of a partner in crime for coffee dates and city adventures. Let’s make life exciting..."
      />
      <div className={"flex justify-end"}>
        {showError && (
          <div className="text-error text-xs opacity-80 mr-4">
            Profile must be at least 20 characters.
          </div>
        )}
        <div
          className={`mb-2 mr-3 text-xs ${isOverLimit ? "text-error" : "text-base-content"} opacity-60`}
        >
          {characterCount} / 500
        </div>
      </div>
    </div>
  );
};
