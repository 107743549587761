import { FiCopy } from "react-icons/fi";
import toast from "react-hot-toast";

const typeToEmoji = {
  funny: "😂",
  sophisticated: "🎩",
  flirty: "😘",
  "giga chad": "💪",
  nerdy: "🤓",
};
const copyToClipboard = (text) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      toast.success("Text copied to clipboard");
    })
    .catch((err) => {
      toast.error("Failed to copy text");
      console.error("Could not copy text: ", err);
    });
};
export const PickupLines = ({ lines }) => {
  return (
    <div className="container mx-auto p-4">
      {lines.map((line, index) => (
        <div key={index} className="p-4">
          <span className="font-bold text-2xl">{typeToEmoji[line.type]} </span>
          <span>{line.line}</span>
          <button
            onClick={() => copyToClipboard(line.line)}
            className="text-lg ml-2"
          >
            <FiCopy />
          </button>
        </div>
      ))}
    </div>
  );
};
