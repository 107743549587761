import { useEffect, useState, useCallback } from "react";

export function useButtonCooldown(key, cooldown = 60) {
  const [timeLeft, setTimeLeft] = useState(0);
  const [isActive, setIsActive] = useState(false); // Add state to track if the cooldown is active

  const startCooldown = useCallback(() => {
    localStorage.setItem(key, Date.now().toString());
    setTimeLeft(cooldown);
    setIsActive(true); // Activate the cooldown
  }, [key, cooldown]);

  useEffect(() => {
    if (!isActive) return; // Do not run the interval if the cooldown is not active

    const interval = setInterval(() => {
      const lastClicked = localStorage.getItem(key);
      if (lastClicked) {
        const now = Date.now();
        const difference = (now - Number(lastClicked)) / 1000;
        const timeRemaining = cooldown - difference;
        if (timeRemaining > 0) {
          setTimeLeft(timeRemaining);
        } else {
          setTimeLeft(0);
          setIsActive(false); // Deactivate the cooldown
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [key, cooldown, isActive]); // Add `isActive` as a dependency

  return { timeLeft, startCooldown };
}
