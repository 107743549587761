import React from "react";

interface CoffeeSupportProps {
  onHide?: () => void;
}

export const CoffeeSupport: React.FC<CoffeeSupportProps> = ({ onHide }) => {
  return (
    <div className="max-w-md justify-center text-center">
      <h3 className="text-lg font-bold">Enjoying the pickup lines?</h3>
      <p className="text-sm font-light">
        Looks like you've been enjoying the pickup lines! If they've sparked a
        conversation or even a date, why not support us with a coffee? It's the
        perfect way to say thanks and keep the love flowing.
      </p>

      <a
        href="https://www.buymeacoffee.com/8BrUTeK"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="https://cdn.buymeacoffee.com/buttons/v2/default-red.png"
          alt="Buy Me A Coffee"
          className="h-12 mx-auto"
        />
      </a>
      {onHide && (
        <button
          onClick={onHide}
          className="text-xs font-light text-gray-500 mt-2 hover:text-gray-700 focus:outline-none"
        >
          No thanks, I'm good
        </button>
      )}
    </div>
  );
};
