import React from "react";
import { useForm } from "react-hook-form";
import Logo from "@assets/icon.svg";
import { ShareButton } from "@components/shareButton";
import { useApi } from "@api/api";

const Support: React.FC = () => {
  const { supportRequestMutation } = useApi();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmit = (data) => {
    supportRequestMutation.mutate(data);
    reset();
  };

  return (
    <div className="container mx-auto px-4">
      <div className="max-w-md mx-auto my-10">
        <div className="flex justify-center items-center space-x-2 mb-6">
          <img src={Logo} alt="logo" className="m-2 w-16" />
          <h1 className="text-2xl font-bold">Support Center</h1>
        </div>
        <p className="mb-4">
          Sorry to hear you are experiencing issues! Please fill out the form
          below to let us know what is happening, or if you have any
          suggestions. We are here to help!
        </p>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <input
            type="text"
            placeholder="Your Name"
            {...register("name", { required: true })}
            className="w-full p-2 rounded"
          />
          {errors.name && <p className="text-red-500">Name is required</p>}

          <input
            type="email"
            placeholder="Your Email"
            {...register("email", { required: true })}
            className="w-full p-2 rounded"
          />
          {errors.email && <p className="text-red-500">Email is required</p>}

          <textarea
            placeholder="Describe your issue or suggestion"
            {...register("message", { required: true })}
            className="w-full p-2 rounded"
          ></textarea>
          {errors.message && (
            <p className="text-red-500">Message is required</p>
          )}

          <button type="submit" className="px-4 py-2 btn btn-primary">
            Submit
          </button>
        </form>

        <div className="flex justify-center items-center space-x-2 my-8">
          <ShareButton />
        </div>

        <div className="text-center mt-8">
          <a
            href="https://www.buymeacoffee.com/8BrUTeK"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://cdn.buymeacoffee.com/buttons/v2/default-red.png"
              alt="Buy Me A Coffee"
              className="h-12 mx-auto m-2"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Support;
