import React from "react";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  return (
    <div className="flex text-xs font-light">
      <Link to="/about">About Us</Link>
      <span className="mx-2">|</span>
      <a href="/privacy-policy.html">Privacy Policy</a>
    </div>
  );
};

export default Footer;
