import React from "react";
import Logo from "@assets/icon.svg";
import { CoffeeSupport } from "@components/coffeeSupport";
import { ShareButton } from "@components/shareButton";

const About: React.FC = () => {
  return (
    <div className="container mx-auto px-4">
      <div className="max-w-md mx-auto my-10">
        <div className="flex justify-center items-center  space-x-2 mb-2">
          <img src={Logo} alt="logo" className="m-2 w-16" />
          <h1 className="text-2xl font-bold mb-4">Our Story</h1>
        </div>
        <p className="mb-6">
          Once upon a screen, in the heart of the digital cosmos, a spark
          ignited—a spark called "The Pickup App." It wasn't born out of mere
          code or algorithms, but from a genuine human moment that blossomed
          into an idea, an idea as old as time yet as fresh as the morning dew.
        </p>
        <p className="mb-6">
          It all began with two colleagues, navigating the labyrinth of modern
          work life, armed with keyboards and fueled by coffee. Among them was a
          dreamer, someone who saw beyond the 0s and 1s, someone who found magic
          in the mundane. This person, our founder, had a secret weapon—an AI
          companion named ChatGPT. A marvel of modern engineering, ChatGPT was
          more than just a tool; it was a muse, a digital oracle nestled within
          the confines of a computer.
        </p>
        <p className="mb-6">
          One day, amidst the clatter of keys and the silent symphony of
          thought, a challenge was laid bare. A coworker, known for his charm
          yet perpetually tongue-tied in the digital dating world, sought
          assistance. "How do I capture attention, spark interest, ignite
          laughter with mere words?" he pondered. Our founder, with a spark of
          inspiration, turned to ChatGPT. Together, they crafted lines not just
          of text but of connection, wit, and warmth. Lines that were genuine,
          clever, and, above all, effective.
        </p>
        <p className="mb-6">
          Seeing the transformation, the joy of conversation sparked, and
          connections made, an idea took root. "What if," thought our founder,
          "we could democratize this magic? What if everyone could wield the
          power of words with the grace of a poet and the wit of a comedian?"
          And so, the journey began.
        </p>
        <p className="mb-6">
          Nights turned into days, codes became features, and ideas solidified
          into reality. The dream was no longer a mere whisper in the wind but a
          tangible place in the digital expanse—a site dedicated to the art and
          science of pickup lines. A sanctuary for the romantically challenged,
          the shy, the eloquent, and the hopeful.
        </p>
        <p className="mb-6">
          But this was not to be a vault of clichés. No, it was to be a crucible
          of creativity, powered by the most advanced AI, yet guided by the
          human heart. A place where algorithms met empathy, where data danced
          with desire.
        </p>
        <p className="mb-6">
          The site was made public, not for fame or fortune, but because we
          believed in the power of connection, in the beauty of a well-placed
          word, and in the laughter shared across screens. We believed in giving
          everyone the chance to be charming, to be witty, to be heard.
        </p>
        <p className="mb-6">
          And so, The Pickup App exists for you. Whether you're seeking love,
          laughter, or just a moment of connection in the digital age, we're
          here to lend you the words, to give you the confidence, and to remind
          you that behind every screen, there's a heart beating, waiting to
          connect.
        </p>
        <p className="">
          Welcome to The Pickup App, where every line is a possibility, and
          every click could lead to the beginning of something wonderful.
        </p>
        <div className="flex justify-center items-center space-x-2 mb-2 mt-8">
          <ShareButton />
        </div>
        <div className="divider">and</div>
        <a
          href="https://www.buymeacoffee.com/8BrUTeK"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://cdn.buymeacoffee.com/buttons/v2/default-red.png"
            alt="Buy Me A Coffee"
            className="h-12 mx-auto m-2"
          />
        </a>
      </div>
    </div>
  );
};

export default About;
