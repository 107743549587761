import React from "react";
import toast from "react-hot-toast";
import { CgShare } from "react-icons/cg";
import { tagEvent } from "@components/util";
import { shareOnMobile } from "react-mobile-share";
export const ShareButton: React.FC = () => {
  const shareDesktop = async () => {
    const rootUrl = window.location.origin;
    const message = "Come try this: ";
    const shareText = `${message} ${rootUrl}`;

    try {
      if (navigator.share) {
        await navigator.share({
          title: document.title,
          text: shareText,
          url: rootUrl,
        });
      } else {
        await navigator.clipboard.writeText(shareText);
        toast.success("Link copied to clipboard!");
      }
    } catch (error) {
      if (error instanceof DOMException && error.name === "NotAllowedError") {
        toast.error(
          "Copying to clipboard failed. This action is likely blocked by your browser.",
        );
      } else {
        toast.error("An unknown error occurred.");
        console.error("Error sharing:", error);
      }
    }
  };
  const share = async () => {
    tagEvent("page_share", { button_name: "Share" });
    shareOnMobile(
      {
        text: "Check this out",
        url: `${window.location.origin}`,
        title: "The Pickup App",
      },
      shareDesktop,
    );
  };

  return (
    <button className="btn btn-secondary" onClick={share}>
      Share this site with friends
      <CgShare />
    </button>
  );
};
