import { useCallback, useState } from "react";

export function useLocalStorageCounter(
  key: string,
  initialValue: number = 0,
): [number, () => void] {
  const [storedValue, setStoredValue] = useState<number>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? parseInt(item, 10) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  const setValue = useCallback(
    (value: number | ((val: number) => number)) => {
      try {
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;
        setStoredValue(valueToStore);
        window.localStorage.setItem(key, valueToStore.toString());
      } catch (error) {
        console.error(error);
      }
    },
    [key, storedValue],
  );

  const increment = useCallback(() => {
    setValue((prevValue) => prevValue + 1);
  }, [setValue]);

  return [storedValue, increment];
}
