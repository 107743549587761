import React from "react";
import { RouterProvider } from "react-router-dom";
import ReactDOM from "react-dom/client";
import router from "./router.jsx";
import "./index.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Toaster } from "react-hot-toast";
import { registerSW } from "virtual:pwa-register";
import { initDataLayer, install } from "ga-gtag";
import { needsGoogleAnalytics } from "@components/util";
const queryClient = new QueryClient();
registerSW({ immediate: true });

if (needsGoogleAnalytics) {
  initDataLayer();
  install("G-Q0HW2ZCXW4");
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Toaster />
      <RouterProvider router={router} />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>,
);
