export const HowTo = () => {
  return (
    <div className="mx-auto p-4 max-w-md">
      <h1 className="text-2xl font-bold mb-4">How to use</h1>
      <ol className="list-decimal list-inside space-y-2">
        <li>Paste the profile of your match</li>
        <li>Optionally add a name for more personal lines</li>
        <li>Hit the button for 5 pickup lines.</li>
        <li>Click the copy and start the conversation!</li>
      </ol>
      <p className="mt-4">
        The Pickup App creates the perfect opener for any match. Simple, quick,
        and effective—make your first impression count!
      </p>
    </div>
  );
};
