import Logo from "@assets/icon.svg";

export const TitleBar = () => {
  return (
    <div className="flex items-center justify-center flex-col p-4">
      <div className="flex items-center space-x-2 mb-2">
        <img src={Logo} alt="logo" className="m-2 w-16" />
        <h1 className="text-2xl font-bold">The Pickup App</h1>
      </div>
      <p className="font-light text-sm text-gray-600">
        Dating openers made easy.
      </p>
    </div>
  );
};
