import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";
import { getAPIURL } from "@api/apiUrl";

const apiUrl = getAPIURL();

async function postRequest(
  url: string,
  body: Record<string, any>,
): Promise<Response> {
  const headers: HeadersInit = { "Content-Type": "application/json" };
  const ret = await fetch(apiUrl + url, {
    method: "POST",
    headers,
    body: JSON.stringify(body),
  });

  return await ret.json();
}

interface PickupLine {
  type: string;
  line: string;
}

interface PickupResponse {
  lines: PickupLine[];
}

interface SupportRequest {
  name: string;
  email: string;
  message: string;
}

interface SupportResponse {
  message?: string;
  error?: string;
  status_code?: number;
}

export const useApi = () => {
  const queryClient = useQueryClient();

  const usePickuplineQuery = (data): UseQueryResult<PickupResponse, unknown> =>
    useQuery({
      queryKey: ["profile", data],
      queryFn: async () => postRequest("lines", data),
      enabled: !!data,
    });

  const refreshPickupline = (data) => {
    return queryClient.invalidateQueries({ queryKey: ["profile", data] });
  };

  const supportRequestMutation = useMutation<
    SupportResponse,
    Error,
    SupportRequest
  >({
    mutationFn: async (supportRequestData: SupportRequest) => {
      return (await postRequest(
        "support_request",
        supportRequestData,
      )) as SupportResponse;
    },
  });
  return {
    usePickuplineQuery,
    supportRequestMutation,
    refreshPickupline,
    queryClient,
  };
};
