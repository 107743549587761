import LandingPage from "@views/landing.view";
import { createBrowserRouter } from "react-router-dom";
import About from "@views/about.view";
import Support from "@views/support.view";

let makeRouter = createBrowserRouter;

const router = makeRouter([
  {
    children: [
      {
        path: "/",
        element: <LandingPage />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/support",
        element: <Support />,
      },
    ],
  },
]);

export default router;
